<template>
  <div style="min-height: 100vh;background: linear-gradient(140deg, #163876, #2A5298);">
    <navbar @asideFlagShow="asideFlagShow"></navbar>
    <div class="main">
      <div class="node"  >
            <div class="level">{{$t("node.grade")}}</div>
            <div class="global" v-if="userInfo.isNode != 1">{{$t("node.domesticconsumer")}}</div>
            <div class="global" v-else>{{$t("node.nodeuser")}}</div>
            <div class="btn" style="text-align:left">
                <van-button round type="info" color="#00A8FF" @click="torights"  size="small" style="margin-right:10px">{{$t("node.interestss")}}</van-button>
                <van-button round type="info" color="#8D94FF"  size="small" @click="toinvite">{{$t("node.share")}}</van-button>
            </div>
            <van-image
          class="node-p"
          width="86px"
          height="86px"
          :src="require('@/assets/cls/node-p-bg.png')"
        />
          <div class="word">P{{ userInfo.lv }}</div>
        </div>
      <div class="bonus">
        <div class="info">
          <van-image width="123px" height="123px" :src="require('@/assets/cls/bonus.png')" />
          <div>${{setting.node_share_bonus}}</div>
          <div>{{$t("node.nodeDividend")}}</div>
        </div>
        <div class="bonus-num">
          <div>
            <div>{{$t("node.dividend")}}</div>
            <div>${{setting.node_fee}}</div>
          </div>
          <div>
            <div>{{$t("node.NFTdividend")}}</div>
            <div>${{setting.node_nft}}</div>
          </div>
        </div>
      </div>
      <div class="explain">
        <div class="title">{{$t("node.description")}}</div>
        <div
          class="word"
        >{{$t("node.descriptionText")}}</div>
        <div class="bottom-info">
          <div>
            <div>{{$t("node.quota")}}</div>
            <div>{{setting.node_number}}</div>
          </div>
          <div>
            <div>{{$t("node.SellingPrice")}}</div>
            <div>${{setting.node_price}}</div>
          </div>
        </div>
      </div>
      <div class="rights">
        <div class="title">{{$t("node.interests")}}</div>
        <div class="word">
          1.{{$t("node.P5interests")}}
          <br />2.{{$t("node.commission")}}
          <br />3.{{$t("node.Nftcommission")}}
        </div>
      </div>
      <div class="subsidy"  v-if="userInfo.isNode == 1">
            <div class="title">{{$t("node.subsidy")}}</div>
          <div
            class="word"
          >
         <p>1.{{$t("node.performanceText1")}}</p> 
         <p>2.{{$t("node.performanceText2")}}</p> 
         <p>3.{{$t("node.performanceText3")}}</p> 
<div class="btn">
        <van-button color="#00A8FF" :disabled="userInfo.is_apple==0" @click="applyFor">{{$t("node.gzsSubsidy")}}</van-button>

      </div>
  </div>
        </div>
      <div class="btn">
        <van-button color="#00A8FF" @click="becomeNode" v-if="userInfo.isNode == 0" :disabled="userInfo.can_buy==0">{{$t("node.becomeNode")}}</van-button>
      </div>
    </div>
    <asidePopup @asideFlagShow="asideFlagShow" style="text-align:left" :asideFlag="asideFlag"></asidePopup>
  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components: { asidePopup },
  data() {
    return {
      asideFlag: false,
      setting: {},
      userInfo: {},
    };
  },
  methods: {
    // 工作室补贴申请
    applyFor(){
      
      this.$dialog
          .confirm({
            title:this.$t('node.gzsSubsidy'),
            message:this.$t('node.message'),
            confirmButtonColor: "#00A8FF",
            confirmButtonText:this.$t('nft.submit'),
            cancelButtonText:this.$t('nft.cancel')
          })
          .then(() => {
            this.$axios.post(this.$api.subsidy, {}).then(res => {
            

    this.getUserInfo();
        
      });
          })
          .catch(() => {
            // on cancel
          });
    },
    torights(){
        this.$router.push({name:'rights'})
    },
    toinvite(){
      this.$router.push({ name: "myShare" });

    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    // 成为节点
    becomeNode() {
      this.$dialog
          .confirm({
            title:this.$t('node.become'),
            message: `${this.$t('node.cost')}${this.setting.node_price}${this.$t('node.usdtNode')}`,
            confirmButtonColor: "#00A8FF",
            confirmButtonText:this.$t('nft.submit'),
            cancelButtonText:this.$t('nft.cancel')
          })
          .then(() => {
            this.$axios.post(this.$api.buy_node, {}).then(res => {
              this.$store.dispatch("getSetting"); //网站配置

    this.getUserInfo();
        
      });
          })
          .catch(() => {
            // on cancel
          });
    },
    getUserInfo() {
      this.$axios.get(this.$api.userInfo, {}).then(res => {
        this.userInfo = res.data;
      });
    },
    msgHandle() {
      let setting = JSON.parse(this.$store.state.setting);
      setting.forEach(item => {
        if (item.key == "node_share_bonus") {
          this.setting.node_share_bonus = item.value;
        }
        if (item.key == "node_fee") {
          this.setting.node_fee = item.value;
        }
        if (item.key == "node_nft") {
          this.setting.node_nft = item.value;
        }
        if (item.key == "node_number") {
          this.setting.node_number = item.value;
        }
        if (item.key == "node_price") {
          this.setting.node_price = item.value;
        }
      });
    }
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    this.msgHandle();
    this.getUserInfo();
  }
};
</script>
<style lang="scss" scoped>
.main {
  padding: 10px 15px 48px;
  .node {
      width: 100%;
     
      background: url("../assets/cls/personal-node-bg.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 10px;
      padding: 14px 0 19px;
      position: relative;

      .node-p {
        position: absolute;
        top: 11px;
        right: 23px;
        display: flex;
        align-items: center;
      }
      .word{
        position: absolute;
        top: 43px;
        right: 55px;
        font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 800;
color: #465273;


background: linear-gradient(194deg, #FDF2DC 0%, #FEEACA 99.6337890625%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
      }
        .level{
            font-size: 10px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #664630;margin:0 0 34px 19px;
        }
        .global{
            font-size: 30px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #664630;margin:0 0 30px 25px;
        }
        .btn{
            margin-left: 18px;
            
        }
    }
  .bonus {
    box-sizing: border-box;

    background: linear-gradient(147deg, #56b0fc, #1c53e3, #06152a);
    border-radius: 10px;
    padding: 24px 15px 20px;
    .info {
      text-align: center;
      margin-bottom: 29px;
      div:nth-of-type(2) {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #e6a23c;
        margin: 6px 0 11px;
      }
      div:nth-of-type(3) {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #bad7ff;
      }
    }
    .bonus-num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        div:first-child {
          font-size: 12px;
          font-family: Source Han Sans CN;
          margin-bottom: 5px;
          font-weight: 400;
          color: #bad7ff;
        }
        div:last-child {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
  .explain,
  .rights {
    margin: 9px 0 10px;
    padding: 16px 14px 19px;
    background: #455fb5;
    border-radius: 10px;
    .title {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .word {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #bad7ff;
      line-height: 18px;
    }
    .bottom-info {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
      > div {
        div:first-child {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #bad7ff;
          margin-bottom: 12px;
        }
        div:last-child {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
  .subsidy
 {
      margin: 9px 0 45px;
      padding: 16px 14px 19px;
      background: #455fb5;
      border-radius: 10px;
      .title {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .word {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #bad7ff;
        
line-height: 24px;
.btn{
    margin-top: 33px;text-align: center
}
      }
      .bottom-info {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        > div {
          div:first-child {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #bad7ff;
            margin-bottom: 12px;
          }
          div:last-child {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
  .btn {
    margin-top: 43px;
    text-align: center;
  }
}
</style>